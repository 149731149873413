
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {Box} from '@mui/material';
import './style.css'

const index = ()=>{
  return (
    <>
        <Box className="center_div">
          <CircularProgress size="45px" id="loader" />
        </Box>
    </>
  )
}

export default index;