import React, {useEffect, useState} from 'react';
import Home from './components/home/home';
import axios from './axios';
import {useHistory, Switch, Route} from 'react-router-dom';
// import { NavigationContainer } from "@react-navigation/native";
// import { createNativeStackNavigator } from "@react-navigation/native-stack";
// import {navigationRef, isReadyRef} from './router/navigate'
import Loader from './components/loader/index';

const App = ()=>{
    const history = useHistory();
    const [profile, setProfile] = useState({});
    const [authenticated, setAuthenticated] = useState(false);
    const [visibleloader, setVisibleLoader] = useState(false);

    const checkCookie = async ()=>{
        try{
            const defaultHeaders = {
                "Content-Type": "application/json"
            };
            const getAllUserInfoResponse = await axios.get('/user/me',{
                headers: defaultHeaders,
                "withCredentials": true
            });
            return getAllUserInfoResponse;
        }
        catch(err){
            return {
                data: {        
                    status: false,
                    data: null
                }
            }
        }
    }

    useEffect(async ()=>{
        setVisibleLoader(!visibleloader);
        const user_data = await checkCookie();
        console.log("Printing user_data api call response");
        console.log(user_data);
        
        setVisibleLoader(!visibleloader);
        if(user_data.data.status){
            if(!user_data.data.data.flag_customer)
                window.location.href = process.env.REACT_APP_CBADMIN_REDIRECT_URL;
            setAuthenticated(true);
            setProfile(user_data.data.data);
        }
        else{
            window.location.href = process.env.REACT_APP_LOGIN_REDIRECT_URL;
        }
    }, [])

    // const links = {
    //     config: {
    //         screens: {
    //             "ComplianceBrain": "/"
    //         }
    //     }
    // };

    // const Stack = createNativeStackNavigator();

    // return (
    //     authenticated ? (
    //     <>
    //         <NavigationContainer ref={navigationRef} onReady={() => { isReadyRef.current = true; }} linking={links}>
    //             <Stack.Navigator initialRouteName="ComplianceBrain">
    //                 <Stack.Screen name="ComplianceBrain" options={{title: "ComplianceBrain"}} component={()=>{return <Home profile={profile} />}} />
    //             </Stack.Navigator>
    //         </NavigationContainer>
    //         {/* <Home  profile={profile}/> */}
    //     </> ) : visibleloader && <Loader />
    // )

    return (
        authenticated ? (
            <>
                <Switch>
                    <Route path="/" component={()=>{
                        return <Home profile={profile} />
                    }} exact={true} />
                </Switch>
            </>
        ) : visibleloader === true && <Loader />
    )
};

export default App ;
