import React from 'react';
import { CommonActions } from '@react-navigation/native';
// import { StackActions } from '@react-navigation/native';
export const navigationRef = React.createRef();
export const isReadyRef = React.createRef();


export const resetroute = () => {
    if (isReadyRef.current && navigationRef.current) {
        // alert("h")
        // navigationRef.current.navigation.dispatch(StackActions.popToTop());
        navigationRef.current.dispatch(
            CommonActions.reset({
                index: 0,
                routes: [
                    { name: 'ComplianceBrain' }
                ],
            })
        );
        // navigationRef.current.dispatch(CommonActions.);
    }
}