// import React, {useState} from 'react';
// import '../../css/navbar/navbar.css';
// import Name from '../../hooks/name';
// import { resetroute } from '../../router/navigate';

// const Navbar = ({profile, navigation})=>{
//     const fname = profile.first_name;
//     const lname = profile.last_name;
//     const [menuOpen, setMenuOpen] = useState(false);
//     const ToggleMenu = (e)=>{
//         // console.log(menuOpen)
//         setMenuOpen(!menuOpen);
//         // let Target;
//         // if(e.target.className == "down-arrow-btn")
//         //     Target = e.target.nextSibling;
//         // else if(e.target.id === "svg")
//         //     Target = e.target.parentElement.nextSibling;
//         // else if(e.target.id === "path")
//         //     Target = e.target.parentElement.parentElement.nextSibling;
//         // // console.log(e, Target);
//         // if(menuOpen === false){
//         //     Target.setAttribute('class', "toggleOpen");
//         // }
//         // else{
//         //     Target.setAttribute('class', "toggleOpen d_none");
//         // }
//         // console.log(e.target.parentElement.nextElementSibling);
//     }
//     return (
//         <>
//             <div className="navbar">
//                 <div className="toggle">
//                     <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
//                         <path d="M19.573 2.514C19.6438 2.514 19.7139 2.50006 19.7793 2.47297C19.8447 2.44588 19.9041 2.40618 19.9541 2.35613C20.0042 2.30608 20.0439 2.24666 20.071 2.18127C20.0981 2.11587 20.112 2.04578 20.112 1.975V0.539C20.112 0.396048 20.0552 0.258951 19.9541 0.157869C19.853 0.0567872 19.716 0 19.573 0H0.539C0.396048 0 0.258952 0.0567872 0.157869 0.157869C0.0567873 0.258951 0 0.396048 0 0.539L0 1.976C0 2.11895 0.0567873 2.25605 0.157869 2.35713C0.258952 2.45821 0.396048 2.515 0.539 2.515L19.573 2.514ZM19.573 9.697C19.716 9.697 19.853 9.64021 19.9541 9.53913C20.0552 9.43805 20.112 9.30095 20.112 9.158V7.722C20.112 7.57905 20.0552 7.44195 19.9541 7.34087C19.853 7.23979 19.716 7.183 19.573 7.183H0.539C0.396048 7.183 0.258952 7.23979 0.157869 7.34087C0.0567873 7.44195 0 7.57905 0 7.722L0 9.159C0 9.30195 0.0567873 9.43905 0.157869 9.54013C0.258952 9.64121 0.396048 9.698 0.539 9.698L19.573 9.697ZM19.573 16.88C19.716 16.88 19.853 16.8232 19.9541 16.7221C20.0552 16.621 20.112 16.484 20.112 16.341V14.905C20.112 14.762 20.0552 14.625 19.9541 14.5239C19.853 14.4228 19.716 14.366 19.573 14.366H0.539C0.396048 14.366 0.258952 14.4228 0.157869 14.5239C0.0567873 14.625 0 14.762 0 14.905L0 16.341C0 16.484 0.0567873 16.621 0.157869 16.7221C0.258952 16.8232 0.396048 16.88 0.539 16.88H19.573Z" fill="#2B3C55"/>
//                     </svg>
//                 </div>
                
//                 <button className="user" style={{cursor: 'pointer'}} onBlur={ToggleMenu} onFocus={ToggleMenu}>
//                     <p>{fname} {lname}</p>
//                     <button className="down-arrow-btn">
//                         <svg id="svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none" className="down-arrow">
//                             <path id="path" d="M5.99997 7.42017C5.7849 7.42017 5.56987 7.33806 5.4059 7.17417L0.24617 2.01439C-0.0820565 1.68616 -0.0820565 1.154 0.24617 0.825904C0.574263 0.497811 1.10632 0.497811 1.43457 0.825904L5.99997 5.39156L10.5654 0.826063C10.8936 0.49797 11.4256 0.49797 11.7537 0.826063C12.0821 1.15416 12.0821 1.68632 11.7537 2.01455L6.59404 7.17433C6.42999 7.33824 6.21495 7.42017 5.99997 7.42017Z" fill="#676767"/>
//                         </svg>
//                     </button>
//                     {menuOpen && <div className="toggleOpen" >
//                         <ul>
//                             <li><button onFocus={console.log("h")} >Settings</button></li>
//                             <li><button>Logout</button></li>
//                         </ul>
//                     </div>}
//                 </button>
//                 <div className="explore">
//                     <img src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-dashboard-home-page/explore.png" alt="explore button" />
//                 </div>
//             </div>
//         </>
//     )
// };

// export default Navbar;

import React, { useState } from 'react';
import '../../css/navbar/navbar.css';
import axios from '../../axios';
import Loader from '../loader/index';
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Box, Typography } from '@mui/material';
import { resetroute } from '../../router/navigate';

const Navbar = (props) => {
    const fname = props?.profile?.first_name;
    const lname = props?.profile?.last_name;
    const [open_user, setOpenUser] = useState(false);
    const anchorRefUser = React.useRef(null);
    const [visibleloader, setVisibleLoader] = useState(false);

    const handleToggleUser = () => {
        setOpenUser((prevOpen) => !prevOpen);
      };
    
      const handleCloseUser = (event) => {
        if (anchorRefUser.current && anchorRefUser.current.contains(event.target)) {
          return;
        }
    
        setOpenUser(false);
      };

      const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
      };
    
      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
      };

    return (
        <>
            {
                visibleloader === true && <Loader />
            }
            <Box className="navbar" style={{ position: 'fixed', top: 0, left: 0 }}>
            {/* <Box className="toggle">
                     <svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="none">
                         <path d="M19.573 2.514C19.6438 2.514 19.7139 2.50006 19.7793 2.47297C19.8447 2.44588 19.9041 2.40618 19.9541 2.35613C20.0042 2.30608 20.0439 2.24666 20.071 2.18127C20.0981 2.11587 20.112 2.04578 20.112 1.975V0.539C20.112 0.396048 20.0552 0.258951 19.9541 0.157869C19.853 0.0567872 19.716 0 19.573 0H0.539C0.396048 0 0.258952 0.0567872 0.157869 0.157869C0.0567873 0.258951 0 0.396048 0 0.539L0 1.976C0 2.11895 0.0567873 2.25605 0.157869 2.35713C0.258952 2.45821 0.396048 2.515 0.539 2.515L19.573 2.514ZM19.573 9.697C19.716 9.697 19.853 9.64021 19.9541 9.53913C20.0552 9.43805 20.112 9.30095 20.112 9.158V7.722C20.112 7.57905 20.0552 7.44195 19.9541 7.34087C19.853 7.23979 19.716 7.183 19.573 7.183H0.539C0.396048 7.183 0.258952 7.23979 0.157869 7.34087C0.0567873 7.44195 0 7.57905 0 7.722L0 9.159C0 9.30195 0.0567873 9.43905 0.157869 9.54013C0.258952 9.64121 0.396048 9.698 0.539 9.698L19.573 9.697ZM19.573 16.88C19.716 16.88 19.853 16.8232 19.9541 16.7221C20.0552 16.621 20.112 16.484 20.112 16.341V14.905C20.112 14.762 20.0552 14.625 19.9541 14.5239C19.853 14.4228 19.716 14.366 19.573 14.366H0.539C0.396048 14.366 0.258952 14.4228 0.157869 14.5239C0.0567873 14.625 0 14.762 0 14.905L0 16.341C0 16.484 0.0567873 16.621 0.157869 16.7221C0.258952 16.8232 0.396048 16.88 0.539 16.88H19.573Z" fill="#2B3C55"/>
                     </svg>
                 </Box> */}
                 <Box className="home-nav">
                    <a style={{cursor:'pointer'}} onClick={()=> resetroute()}>
                        <img src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-Website/logo-colorful.png" alt="Logo" />
                    </a>
                </Box>
                <Box className="user">
                    <Button
                        ref={anchorRefUser}
                        aria-controls={open_user ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggleUser}
                    >
                        <Typography style={{marginBottom: 0 ,textTransform:"capitalize"}} variant="p" paragraph={true}>{fname} {lname}</Typography>
                        <Button className="down-arrow-btn">
                            <svg id="svg" xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none" className="down-arrow">
                                <path id="path" d="M5.99997 7.42017C5.7849 7.42017 5.56987 7.33806 5.4059 7.17417L0.24617 2.01439C-0.0820565 1.68616 -0.0820565 1.154 0.24617 0.825904C0.574263 0.497811 1.10632 0.497811 1.43457 0.825904L5.99997 5.39156L10.5654 0.826063C10.8936 0.49797 11.4256 0.49797 11.7537 0.826063C12.0821 1.15416 12.0821 1.68632 11.7537 2.01455L6.59404 7.17433C6.42999 7.33824 6.21495 7.42017 5.99997 7.42017Z" fill="#676767" />
                            </svg>
                        </Button>
                    </Button>
                    <Popper
                        open={open_user}
                        anchorEl={anchorRefUser.current}
                        role={undefined}
                        transition
                        disablePortal
                        className="dropdown"
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom"
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseUser}>
                                        <MenuList
                                            autoFocusItem={open_user}
                                            id="menu-list-grow"
                                        >
                                            <MenuItem onClick={async ()=>{
                                                setVisibleLoader(!visibleloader);
                                                const logoutresponse = await axios.post("/auth/logout", {} ,{
                                                    headers: {
                                                        "Content-Type": "application/json"
                                                    },
                                                    withCredentials: true
                                                });
                                                console.log("Printing logout response", logoutresponse);
                                                setVisibleLoader(!visibleloader);
                                                if(logoutresponse.data.status === true){
                                                    window.location.replace(process.env.REACT_APP_LOGIN_REDIRECT_URL);
                                                }
                                            }}>Logout</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
                {/* <Box className="explore">
                        <img
                        ref={anchorRef}
                        aria-controls={open ? "menu-list-grow" : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle} src="https://storage.googleapis.com/complaincebrain-public/compliance-brain-dashboard-home-page/explore.png" alt="explore button" />
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom"
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                        >
                                            <MenuItem>Profile</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box> */}
            </Box>
        </>
    )
}

export default Navbar;